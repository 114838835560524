import { FormattedMessage } from "react-intl";

import { NotaryComplianceStatuses } from "graphql_globals";
import { redirectToSubdomain } from "util/application_redirect";
import AppSubdomains from "constants/app_subdomains";
import { DEFAULT_ROUTE as MEMBER_MANAGEMENT_ROUTE } from "common/organization/member_management/routing";
import { SETTINGS_PATH } from "util/routes";
import { usePermissions } from "common/core/current_user_role";

import { BusinessOnboardingHowToItem, SendHowToItem, EasylinkLinkItem } from "./common";
import { NSTRoot } from "../common/root";
import { DeprecatedHeader } from "../common/header";
import { NSTSection, TaskSection } from "../common/section";
import {
  SetupNotaryProfileTaskItem,
  InviteTaskItem,
  AddLogoTaskItem,
  SetupBillingTaskItem,
} from "../common/task_item";
import { NotaryKnowledgeHowToItem, NotaryToolsHowToItem } from "../common/how_to_item";
import { HowItWorksLinkItem, FaqLinkItem } from "../common/link_item";
import type { BusinessGetStarted_viewer_user_notaryProfile as NotaryProfile } from "./business_get_started_query.graphql";
import ProofCertificatePromo from "./proof_certificate_promo";

type Props = {
  name: string;
  hasSeats: boolean;
  hasLogo: boolean;
  notaryProfile: null | NotaryProfile;
  billingSetup: boolean;
  canInviteNotary: boolean;
  canManageTeam: boolean;
};

export function BusinessAdminGetStarted(props: Props) {
  const { name, hasSeats, hasLogo, notaryProfile, billingSetup, canInviteNotary, canManageTeam } =
    props;
  const { hasPermissionFor } = usePermissions();
  const canEditTeamDetails = hasPermissionFor("editTeamMember");
  const canEditOrganizationDetails = hasPermissionFor("editOrganizationDetails");
  const canViewOrganizationTransactions = hasPermissionFor("viewOrganizationTransactions");
  const canCreateEasyLinks = hasPermissionFor("createEasyLinks");
  const canFinishSettingUpAccount = canEditTeamDetails || canEditOrganizationDetails;

  return (
    <NSTRoot>
      <DeprecatedHeader name={name} />

      {canFinishSettingUpAccount && (
        <TaskSection
          title={
            <FormattedMessage
              id="f9ba30b5-5ba6-4ba8-83a6-c5349c1903db"
              defaultMessage="Finish setting up your account"
            />
          }
        >
          {notaryProfile &&
            notaryProfile.complianceStatus !== NotaryComplianceStatuses.COMPLIANT && (
              <SetupNotaryProfileTaskItem complete={false} notaryProfile={notaryProfile} />
            )}
          {canEditTeamDetails && canManageTeam && (
            <InviteTaskItem
              complete={!hasSeats}
              onClick={() => redirectToSubdomain(AppSubdomains.business, MEMBER_MANAGEMENT_ROUTE)}
            />
          )}
          {canEditOrganizationDetails && (
            <AddLogoTaskItem
              complete={hasLogo}
              onClick={() => redirectToSubdomain(AppSubdomains.business, SETTINGS_PATH)}
            />
          )}
          {canEditOrganizationDetails && (
            <SetupBillingTaskItem
              complete={billingSetup}
              onClick={() =>
                redirectToSubdomain(AppSubdomains.business, "/settings/billing/payment-settings")
              }
            />
          )}
        </TaskSection>
      )}
      {notaryProfile && <ProofCertificatePromo notaryProfile={notaryProfile} showCTA />}
      <NSTSection
        title={
          <FormattedMessage
            id="32d75168-e490-4763-b7ab-9a4006e838fa"
            defaultMessage="Proof Resources"
          />
        }
      >
        <BusinessOnboardingHowToItem />
        {canInviteNotary && <NotaryKnowledgeHowToItem />}
        {notaryProfile?.trainingEnabled && <NotaryToolsHowToItem notaryProfile={notaryProfile} />}
        {canViewOrganizationTransactions && !canInviteNotary && <SendHowToItem />}
      </NSTSection>
      <NSTSection
        title={
          <FormattedMessage
            id="84352a2a-b842-4c9a-bcf9-9f55136eb264"
            defaultMessage="Other helpful links"
          />
        }
      >
        <HowItWorksLinkItem />
        {canCreateEasyLinks && <EasylinkLinkItem />}
        <FaqLinkItem />
      </NSTSection>
    </NSTRoot>
  );
}
