import {
  useMemo,
  useEffect,
  useReducer,
  useCallback,
  type ComponentProps,
  type ReactNode,
} from "react";
import { useLocation, useSearchParams, useNavigate, useParams } from "react-router-dom";
import { FormattedMessage, useIntl, defineMessages, type MessageDescriptor } from "react-intl";

import { userFullName } from "util/user";
import { encodeSearchParams } from "util/location";
import { CREATE_TEAM_MEMBER } from "common/target_auth_link_handler/handle_target_auth_link";
import { captureException } from "util/exception";
import {
  OrganizationMembershipActivationState,
  OrganizationMembershipRole,
  type Language,
} from "graphql_globals";
import { isGraphQLError } from "util/graphql/query";
import { useMutation } from "util/graphql";
import { pushNotification } from "common/core/notification_center/actions";
import { NOTIFICATION_TYPES, NOTIFICATION_SUBTYPES } from "constants/notifications";
import { segmentTrack } from "util/segment";
import { SEGMENT_EVENTS } from "constants/analytics";
import Apps from "constants/applications";
import { CURRENT_PORTAL } from "constants/app_subdomains";
import { Label } from "common/core/form/layout";
import RequiredAsterisk from "common/core/form/required-asterisk";

import type AddEditModal from "./add_edit_modal";
import { useManagementParams } from "./routing";
import type EnablementConfirmModal from "./confirmation/enablement";
import type RevokeInvitationConfirmModal from "./confirmation/revoke_invitation";
import type RemoveAccountConfirmModal from "./confirmation/remove_account";
import type NotaryDetailsModal from "./notary_details";
import UpdateOrganizationMembershipMutation from "./update_organization_membership_mutation.graphql";
import CreateOrganizationMembershipMutation from "./create_organization_membership_mutation.graphql";
import CreateOrganizationNotaryMutation from "./create_organization_notary_mutation.graphql";
import RemoveMemberFromOrganizationMutation from "./remove_member_from_organization_mutation.graphql";
import UpdateNotaryProfileLanguagesMutation from "./update_notary_profile_languages_mutation.graphql";
import ResendOrganizationMembershipInvitationMutation from "./resend_organization_membership_invitation_mutation.graphql";
import ResendOrganizationMembershipApprovalMutation from "./resend_organization_membership_approval_mutation.graphql";
import {
  type OrganizationMembershipManagement_organization_Organization as Organization,
  type OrganizationMembershipManagementVariables,
} from "./index_query.graphql";

type AddEditModalProps = ComponentProps<typeof AddEditModal>;
type InitialMemberState = NonNullable<AddEditModalProps["initialMember"]>;
type AwaitingApprovalMember = AddEditModalProps["editingMember"] & {
  id: string;
  email: string;
};
export type EnablementConfirmMember = ComponentProps<typeof EnablementConfirmModal>["member"] & {
  id: string;
  activationState: OrganizationMembershipActivationState;
};
export type RevokeInvitationConfirmMember = ComponentProps<
  typeof RevokeInvitationConfirmModal
>["member"];
export type RemoveAccountConfirmMember = ComponentProps<typeof RemoveAccountConfirmModal>["member"];
export type NotaryDetailsMember = ComponentProps<typeof NotaryDetailsModal>["member"];
type MemberEditState = Readonly<
  | { tag: "none" }
  | {
      tag: "adding-or-editing-member";
      editingMember?: EditMember;
      addEditError:
        | null
        | "limit-reached-error"
        | "already-exists-error"
        | "already-member-in-org-error";
      initialMember?: InitialMemberState;
    }
  | { tag: "adding-member-cc"; editingMember?: EditMember }
  | { tag: "confirming-enable-toggle"; forMember: EnablementConfirmMember; limitReached: boolean }
  | { tag: "confirming-revoke-invitation"; forMember: RevokeInvitationConfirmMember }
  | { tag: "confirming-remove-account"; forMember: RemoveAccountConfirmMember }
  | { tag: "viewing-notary-details"; forNotary: NotaryDetailsMember }
  | {
      tag: "activate-deactivate-members";
      totalCount?: number;
      state: "activate" | "deactivate";
      ids?: string[];
    }
>;
type Action = Readonly<
  | { type: "open-confirm-enable-toggle"; forMember: EnablementConfirmMember }
  | { type: "open-confirm-revoke-invitation"; forMember: RevokeInvitationConfirmMember }
  | { type: "open-confirm-remove-account"; forMember: RemoveAccountConfirmMember }
  | { type: "open-notary-modal"; forNotary: NotaryDetailsMember }
  | { type: "open-add-edit-modal"; editingMember?: EditMember; initialMember?: InitialMemberState }
  | {
      type: "open-activate-deactivate-modal";
      totalCount?: number;
      state: "activate" | "deactivate";
      ids?: string[];
    }
  | { type: "limit-reached-error" }
  | { type: "already-exists-error" }
  | { type: "cancel-error-modal" }
  | { type: "cancel-main-modal" }
  | { type: "already-member-in-org-error" }
  | { type: "command-center-add-modal"; editingMember?: EditMember }
>;
type EditMember = AddEditModalProps["editingMember"] & {
  id: string;
  notaryProfile: null | {
    id: string;
    languages: Language[];
    usState: { id: string };
  };
};

type SaveAddEditInput = {
  firstName: string;
  lastName: string;
  email: string;
  userRoleId: string | null;
  organizationId: string;
  notaryState?: string;
  notaryLangs?: (Language | false)[];
};

type OptionRowProps = {
  htmlFor?: string;
  children: ReactNode;
  styles: { prefix: string; optionRowInput: string };
  label?: ReactNode;
  prefix?: ReactNode;
  postScript?: ReactNode;
  required?: boolean;
};

export type ReadableOrganization = {
  name: string;
  id: string;
  isRootOrganization?: boolean;
};

export const PER_PAGE_COUNT = 50;
const INIT_MEMBER_EDIT_STATE: MemberEditState = Object.freeze({ tag: "none" });
const MESSAGES = defineMessages({
  deactivateAccount: {
    id: "cb53826c-1b67-4b7d-bb4d-10e2740db3b7",
    defaultMessage: "The account has been deactivated.",
  },
  changesSaved: {
    id: "a925491e-e2bf-4b02-b9bc-ab06b0057433",
    defaultMessage: "Changes saved.",
  },
  inviteSent: {
    id: "ecad6db6-ad3f-4198-8c5e-f6e2e7db986b",
    defaultMessage: "Invitation sent!",
  },
  resendInvitation: {
    id: "5b9278fb-0b47-4271-8fc9-6966e61bd556",
    defaultMessage: "The invitation has been resent.",
  },
  revokeInvitation: {
    id: "cb53826c-1b67-4b7d-bb4d-10e2740db3b7",
    defaultMessage: "The invitation has been revoked.",
  },
  errorMessage: {
    id: "3c543ed1-5bc3-4bb7-b562-1dcf8e2d0c55",
    defaultMessage: "We're sorry, something went wrong. Please try again.",
  },
});

export function useBaseMembersVars() {
  const { after, before, grouping, groupings, pageIndex, defaultSort } = useManagementParams();
  return useMemo(() => {
    if (before) {
      return {
        grouping,
        groupings,
        before,
        last: PER_PAGE_COUNT,
        offset: PER_PAGE_COUNT * pageIndex,
        roles: null,
        sortBy: defaultSort,
      };
    }
    return {
      grouping,
      groupings,
      after,
      first: PER_PAGE_COUNT,
      offset: PER_PAGE_COUNT * pageIndex,
      roles: null,
      sortBy: defaultSort,
    };
  }, [after, before, grouping, groupings, pageIndex, defaultSort]);
}

function memberStateReducer(state: MemberEditState, action: Action): MemberEditState {
  switch (action.type) {
    case "cancel-main-modal":
      return INIT_MEMBER_EDIT_STATE;
    case "cancel-error-modal":
      if (state.tag === "confirming-enable-toggle") {
        return { ...state, limitReached: false };
      } else if (state.tag === "adding-or-editing-member") {
        return { ...state, addEditError: null };
      }
      captureException(
        new Error(`Unexpected cancel-error-modal action while in state ${state.tag}`),
      );
      return state;
    case "limit-reached-error":
    case "already-exists-error":
      if (state.tag === "confirming-enable-toggle") {
        return { ...state, limitReached: true };
      } else if (state.tag === "adding-or-editing-member") {
        return { ...state, addEditError: action.type };
      }
      captureException(new Error(`Unexpected ${action.type} action while in state ${state.tag}`));
      return state;
    case "already-member-in-org-error":
      if (state.tag === "adding-or-editing-member") {
        return { ...state, addEditError: action.type };
      }
      captureException(new Error(`Unexpected ${action.type} action while in state ${state.tag}`));
      return state;
    case "open-notary-modal":
      return { tag: "viewing-notary-details", forNotary: action.forNotary };
    case "open-confirm-enable-toggle":
      return { tag: "confirming-enable-toggle", forMember: action.forMember, limitReached: false };
    case "open-confirm-revoke-invitation":
      return { tag: "confirming-revoke-invitation", forMember: action.forMember };
    case "open-confirm-remove-account":
      return { tag: "confirming-remove-account", forMember: action.forMember };
    case "open-add-edit-modal":
      return {
        tag: "adding-or-editing-member",
        editingMember: action.editingMember,
        addEditError: null,
        initialMember: action.initialMember,
      };
    case "command-center-add-modal":
      return {
        tag: "adding-member-cc",
        editingMember: action.editingMember,
      };
    case "open-activate-deactivate-modal":
      return {
        tag: "activate-deactivate-members",
        totalCount: action.totalCount,
        state: action.state,
      };
    default:
      return state;
  }
}

function serializeLang(formLang: Language | false): formLang is Language {
  return Boolean(formLang);
}

export function hasNotaryLangsUpdated(
  notaryLangs?: Language[],
  selectedLangs?: (Language | false)[],
) {
  if (!notaryLangs || !selectedLangs) {
    return false;
  }
  const filteredNotaryLangs = selectedLangs.filter(serializeLang);
  return (
    filteredNotaryLangs.length !== notaryLangs.length ||
    !filteredNotaryLangs.every((lang) => notaryLangs.includes(lang))
  );
}

export function useDeepLinkModalState(
  openModal: ({ initialMember }: { initialMember: InitialMemberState }) => void,
) {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const initiateType = searchParams.get("initiate");

  const onCleanup = () => {
    const search = encodeSearchParams(searchParams, {
      initiate: null,
      member_email: null,
      member_firstname: null,
      member_lastname: null,
    });
    navigate(`${pathname}?${search}`);
  };

  useEffect(() => {
    if (initiateType === CREATE_TEAM_MEMBER) {
      openModal({
        initialMember: {
          email: searchParams.get("member_email"),
          firstName: searchParams.get("member_firstname"),
          lastName: searchParams.get("member_lastname"),
        },
      });
    }
  }, []);

  return onCleanup;
}

export function getOrganizationsInfo(organization: Organization): ReadableOrganization[] {
  return [
    {
      name: organization.name || "",
      id: organization.id,
      isRootOrganization: true,
    },
    ...organization.publicSubsidiaryOrganizations.map((org) => ({
      name: org.name || "",
      id: org.id,
    })),
  ];
}

export function OptionFormRow({
  htmlFor,
  label,
  prefix,
  postScript,
  children,
  styles,
  required,
}: OptionRowProps) {
  return (
    <>
      {label && (
        <Label htmlFor={htmlFor}>
          {label}
          {required && <RequiredAsterisk />}
        </Label>
      )}{" "}
      <br />
      {prefix && <span className={styles.prefix}>{prefix}</span>}
      <div className={styles.optionRowInput}>
        {children}
        {postScript}
      </div>
    </>
  );
}

export function useMemberEditControl(
  activeOrgId: string,
  queryVariables: OrganizationMembershipManagementVariables,
) {
  const createOrganizationMembershipMutateFn = useMutation(CreateOrganizationMembershipMutation);
  const createOrganizationNotaryMutateFn = useMutation(CreateOrganizationNotaryMutation);
  const updateOrganizationMembershipMutateFn = useMutation(UpdateOrganizationMembershipMutation);
  const removeMemberFromOrganizationMutateFn = useMutation(RemoveMemberFromOrganizationMutation);
  const updateNotaryProfileLanguagesMutateFn = useMutation(UpdateNotaryProfileLanguagesMutation);
  const resendInvitationEmailFn = useMutation(ResendOrganizationMembershipInvitationMutation);
  const resendAdminApprovalFn = useMutation(ResendOrganizationMembershipApprovalMutation);
  const adminActiveOrgId = useParams().globalID!;
  const intl = useIntl();
  const successNotification = (message: MessageDescriptor) => {
    pushNotification({
      type: NOTIFICATION_TYPES.DEFAULT,
      subtype: NOTIFICATION_SUBTYPES.SUCCESS,
      message: intl.formatMessage(message),
      position: "topCenter",
    });
  };
  const errorNotification = () =>
    pushNotification({
      type: NOTIFICATION_TYPES.DEFAULT,
      subtype: NOTIFICATION_SUBTYPES.ERROR,
      message: intl.formatMessage(MESSAGES.errorMessage),
      position: "topCenter",
    });

  const [state, dispatch] = useReducer(memberStateReducer, INIT_MEMBER_EDIT_STATE);
  const handleCancelModal = useCallback(() => dispatch({ type: "cancel-main-modal" }), []);

  const handleExitErrorModal = useCallback(() => dispatch({ type: "cancel-error-modal" }), []);
  const handleMutationErrors = useCallback((e: Error) => {
    const inviteFormError =
      e.message.includes("membership_already_in_org") ||
      e.message.includes("membership_already_exists") ||
      e.message.includes("email_already_registered");
    if (!inviteFormError) {
      errorNotification();
    }
    const isMutationGraphError = isGraphQLError(e);
    if (isMutationGraphError && e.message.includes("employee_limit_reached")) {
      dispatch({ type: "limit-reached-error" });
    } else if (
      isMutationGraphError &&
      (e.message.includes("membership_already_exists") ||
        e.message.includes("email_already_registered"))
    ) {
      dispatch({ type: "already-exists-error" });
    } else if (isMutationGraphError && e.message.includes("membership_already_in_org")) {
      dispatch({ type: "already-member-in-org-error" });
    }
    return Promise.reject(e);
  }, []);

  const handleOpenEnableToggleModal = useCallback((forMember: EnablementConfirmMember) => {
    dispatch({ type: "open-confirm-enable-toggle", forMember });
  }, []);

  const handleCCOpenModal = useCallback(
    ({ editingMember }: { editingMember?: EditMember } = {}) => {
      dispatch({ type: "command-center-add-modal", editingMember });
    },
    [],
  );

  const handleActivateDeactivateModal = useCallback(
    ({
      totalCount,
      state,
      ids,
    }: { totalCount?: number; state?: "activate" | "deactivate"; ids?: string[] } = {}) => {
      dispatch({
        type: "open-activate-deactivate-modal",
        totalCount,
        state: state || "deactivate",
        ids,
      });
    },
    [],
  );

  const handleConfirmMemberEnableToggle = useCallback(
    ({ id, activationState }: EnablementConfirmMember) => {
      const input = {
        id,
        activationState:
          activationState === OrganizationMembershipActivationState.DISABLED
            ? OrganizationMembershipActivationState.ACTIVE
            : OrganizationMembershipActivationState.DISABLED,
      };
      const variables = {
        ...queryVariables,
        input,
      };

      const deactivateAccountNotification = () =>
        activationState === OrganizationMembershipActivationState.ACTIVE &&
        successNotification(MESSAGES.deactivateAccount);

      return updateOrganizationMembershipMutateFn({ variables })
        .then(deactivateAccountNotification)
        .then(handleCancelModal)
        .catch(handleMutationErrors);
    },
    [updateOrganizationMembershipMutateFn, handleCancelModal, handleMutationErrors, queryVariables],
  );

  const handleOpenRevokeInvitationModal = useCallback(
    (forMember: RevokeInvitationConfirmMember) => {
      dispatch({ type: "open-confirm-revoke-invitation", forMember });
    },
    [],
  );
  const handleConfirmRevokeInvitation = useCallback(
    ({ id }: RevokeInvitationConfirmMember) => {
      const variables = {
        ...queryVariables,
        input: { id },
      };

      return removeMemberFromOrganizationMutateFn({ variables })
        .then(() => successNotification(MESSAGES.revokeInvitation))
        .then(handleCancelModal)
        .catch(() => {
          errorNotification();
        });
    },
    [removeMemberFromOrganizationMutateFn, handleCancelModal, queryVariables],
  );

  const handleOpenRemoveAccountModal = useCallback((forMember: RemoveAccountConfirmMember) => {
    dispatch({ type: "open-confirm-remove-account", forMember });
  }, []);
  const handleConfirmRemoveAccount = useCallback(
    ({ id, firstName, lastName }: RemoveAccountConfirmMember) => {
      const variables = {
        ...queryVariables,
        input: { id },
      };
      return removeMemberFromOrganizationMutateFn({ variables }).then(() => {
        pushNotification({
          type: NOTIFICATION_TYPES.DEFAULT,
          position: "topCenter",
          message: (
            <FormattedMessage
              id="3f20dbb8-009c-4798-9b7e-8a5b77eaf6b6"
              defaultMessage="{fullName}'s account has been removed."
              values={{ fullName: userFullName({ firstName, lastName }) }}
            />
          ),
        });
        handleCancelModal();
      });
    },
    [removeMemberFromOrganizationMutateFn, handleCancelModal, queryVariables],
  );

  const handleOpenAddEditModal = useCallback(
    ({
      editingMember,
      initialMember,
    }: { editingMember?: EditMember; initialMember?: InitialMemberState } = {}) => {
      dispatch({ type: "open-add-edit-modal", editingMember, initialMember });
    },
    [],
  );

  function createUpdateNotary(
    mutationInput: SaveAddEditInput,
    editingMember?: Parameters<AddEditModalProps["onSave"]>[1],
    options?: {
      notarizeDocumentAccess: boolean;
      isAdminPortal: boolean;
      commandCenterRole: string | undefined;
    },
  ) {
    const { firstName, lastName, organizationId, email, userRoleId, notaryLangs, notaryState } =
      mutationInput;
    const { notarizeDocumentAccess, isAdminPortal, commandCenterRole } = options || {};
    const editMemberWithNotaryProfile = Boolean(editingMember?.notaryProfile);
    if (!editMemberWithNotaryProfile && !(notaryState || notaryLangs)) {
      throw new Error("Cannot create notary without proper state or languages");
    }
    const newRoleSchedules = [{ userRoleId }];
    if (commandCenterRole) {
      newRoleSchedules.push({ userRoleId: commandCenterRole });
    }
    if (notarizeDocumentAccess && !editMemberWithNotaryProfile && notaryLangs && notaryState) {
      return createOrganizationNotaryMutateFn({
        variables: {
          ...queryVariables,
          input: {
            firstName,
            lastName,
            organizationId,
            email,
            roleSchedules: newRoleSchedules,
            languages: notaryLangs.filter(serializeLang),
            usStateId: notaryState,
          },
        },
      });
    } else if (notarizeDocumentAccess && !isAdminPortal && notaryLangs && editingMember) {
      if (hasNotaryLangsUpdated(editingMember.notaryProfile?.languages, notaryLangs)) {
        return updateNotaryProfileLanguagesMutateFn({
          variables: {
            input: {
              notaryProfileId: editingMember.notaryProfile!.id,
              languages: notaryLangs.filter(serializeLang),
            },
          },
        });
      }
    }

    return Promise.resolve();
  }

  function createUpdateMembership(
    mutationInput: SaveAddEditInput,
    editingMember?: Parameters<AddEditModalProps["onSave"]>[1],
    options?: {
      notarizeDocumentAccess: boolean;
      isAdminPortal: boolean;
      commandCenterRole: string | undefined;
    },
  ) {
    const { firstName, lastName, email, userRoleId, organizationId } = mutationInput;
    const { notarizeDocumentAccess, commandCenterRole } = options || {};
    // Editing a member. This needs to happen even if we are creating a notary profile for an existing user
    if (editingMember && userRoleId) {
      const newValue = { userRoleId };
      const removeExisting = editingMember.roleSchedules
        ? editingMember.roleSchedules.map((role) => ({
            id: role.id,
            delete: true,
          }))
        : [];

      const allRoleSchedules = [...removeExisting, newValue];
      if (commandCenterRole) {
        allRoleSchedules.push({ userRoleId: commandCenterRole });
      }
      const input = { id: editingMember.id, roleSchedules: allRoleSchedules };
      return updateOrganizationMembershipMutateFn({
        variables: {
          ...queryVariables,
          input,
        },
      });
    } else if (!notarizeDocumentAccess) {
      const newRoleSchedules = [{ userRoleId }];
      if (commandCenterRole) {
        newRoleSchedules.push({ userRoleId: commandCenterRole });
      }
      const input: {
        firstName: string;
        lastName: string;
        organizationId: string;
        email: string;
        roleSchedules: { userRoleId: string | null }[];
      } = {
        firstName,
        lastName,
        organizationId: organizationId
          ? organizationId
          : CURRENT_PORTAL === Apps.ADMIN
            ? adminActiveOrgId
            : activeOrgId,
        email,
        roleSchedules: newRoleSchedules,
      };
      return createOrganizationMembershipMutateFn({
        variables: {
          ...queryVariables,
          input,
        },
      });
    }
    return Promise.resolve();
  }

  const saveAddEditMember = async (
    saveInput: SaveAddEditInput,
    editingMember?: Parameters<AddEditModalProps["onSave"]>[1],
    options?: {
      canDisableOwner?: boolean;
      isAdminPortal?: boolean;
      invitingDirectly?: boolean;
      notarizeDocumentAccess?: boolean;
      commandCenterRole?: string;
    },
  ) => {
    const { email, userRoleId, firstName, lastName } = saveInput;
    const {
      canDisableOwner,
      isAdminPortal,
      invitingDirectly,
      notarizeDocumentAccess,
      commandCenterRole,
    } = options || {};

    if (notarizeDocumentAccess) {
      await createUpdateNotary(saveInput, editingMember, {
        commandCenterRole,
        isAdminPortal: Boolean(isAdminPortal),
        notarizeDocumentAccess: Boolean(notarizeDocumentAccess),
      });
    }

    if (!canDisableOwner && editingMember?.role === OrganizationMembershipRole.OWNER) {
      return Promise.resolve();
    }
    await createUpdateMembership(saveInput, editingMember, {
      commandCenterRole,
      isAdminPortal: Boolean(isAdminPortal),
      notarizeDocumentAccess: Boolean(notarizeDocumentAccess),
    });

    if (isAdminPortal) {
      pushNotification({
        type: NOTIFICATION_TYPES.DEFAULT,
        position: "topCenter",
        message: invitingDirectly ? (
          <FormattedMessage
            id="bf45b79d-a417-4043-a88a-926d94e5de15"
            defaultMessage="{fullName}'s invitation sent!"
            values={{ fullName: userFullName({ firstName, lastName }) }}
          />
        ) : (
          <FormattedMessage
            id="70638f99-5930-403e-b64e-d73a317dfc8e"
            defaultMessage="{fullName}'s changes have been saved."
            values={{ fullName: userFullName({ firstName, lastName }) }}
          />
        ),
      });
    } else {
      successNotification(editingMember ? MESSAGES.changesSaved : MESSAGES.inviteSent);
      if (!editingMember) {
        segmentTrack(SEGMENT_EVENTS.MEMBER_INVITED, {
          source: "Member Management",
          invitee_email: email,
          firstName,
          lastName,
          member_role: userRoleId,
        });
      }
    }
  };

  const handleOpenNotaryDetails = useCallback((member: NotaryDetailsMember) => {
    dispatch({ type: "open-notary-modal", forNotary: member });
  }, []);

  const onResendInvitationEmailClick = useCallback(
    (member: NotaryDetailsMember) => {
      resendInvitationEmailFn({
        variables: {
          input: {
            organizationId: activeOrgId,
            email: member.email,
          },
        },
      })
        .then(() => successNotification(MESSAGES.resendInvitation))
        .catch(() => {
          errorNotification();
        });
    },
    [resendInvitationEmailFn, activeOrgId],
  );

  const onResendAdminApprovalClick = useCallback(
    (member: AwaitingApprovalMember) => {
      return resendAdminApprovalFn({
        variables: {
          input: {
            organizationId: adminActiveOrgId,
            email: member.email,
          },
        },
      }).then(() => {
        pushNotification({
          type: NOTIFICATION_TYPES.DEFAULT,
          position: "topCenter",
          message: (
            <FormattedMessage
              id="6ca881c3-f658-437c-bbd5-7fad75420b2c"
              defaultMessage="Email has been resent to org owners/admins."
            />
          ),
        });
      });
    },
    [resendAdminApprovalFn, activeOrgId],
  );

  return {
    state,
    handleOpenEnableToggleModal,
    handleActivateDeactivateModal,
    handleOpenRevokeInvitationModal,
    handleOpenRemoveAccountModal,
    handleExitErrorModal,
    handleOpenAddEditModal,
    handleCancelModal,
    handleMutationErrors,
    handleConfirmMemberEnableToggle,
    handleConfirmRevokeInvitation,
    handleConfirmRemoveAccount,
    handleOpenNotaryDetails,
    onResendInvitationEmailClick,
    handleCCOpenModal,
    hasNotaryLangsUpdated,
    onResendAdminApprovalClick,
    saveAddEditMember,
  };
}
