import "common/notary/profile_wizard/section/index.scss";

import { memo, useMemo, type ReactNode } from "react";

import StateRequiredEducation, {
  stateEducationSection,
} from "common/notary/profile_wizard/section/state_required_education";
import type { MaybeUpdateUserFn } from "common/notary/onboarding";
import CommissionDetails, {
  commissionDetailsSection,
} from "common/notary/profile_wizard/section/commission_details";
import InsuranceDetails, {
  insuranceDetailsSection,
} from "common/notary/profile_wizard/section/insurance_details";
import DigitalCertificate, {
  certificateSection,
} from "common/notary/profile_wizard/section/digital_certificate";
import SignatureAndSeal, {
  sigAndSealSection,
} from "common/notary/profile_wizard/section/signature_and_seal";
import PayoutOptions, { payoutSection } from "common/notary/profile_wizard/section/payout";
import PersonalInformationSection, {
  personalInfoSection,
} from "common/notary/profile_wizard/section/personal_information";
import Footer from "common/notary/onboarding/footer";
import IdentityVerification, {
  identityVerificationSection,
} from "common/notary/profile_wizard/section/identity_verification";
import ProofCertificate, {
  proofCertificateSection,
} from "common/notary/profile_wizard/section/digital_certificate/proof";

import type { BusinessNotaryProfileOnboardingStep as User } from "./index_fragment.graphql";

type StepProps = {
  user: User;
  activeStep?: Section;
  onNext: MaybeUpdateUserFn;
  renderUnknownStep: () => ReactNode;
  refetch: () => void;
};

type MaybeSection =
  | ReturnType<typeof personalInfoSection>
  | ReturnType<typeof commissionDetailsSection>
  | ReturnType<typeof certificateSection>
  | ReturnType<typeof insuranceDetailsSection>
  | ReturnType<typeof payoutSection>
  | ReturnType<typeof stateEducationSection>
  | ReturnType<typeof sigAndSealSection>
  | ReturnType<typeof identityVerificationSection>
  | ReturnType<typeof proofCertificateSection>;
type Section = Exclude<MaybeSection, false>;

function filterSections(maybeSection: MaybeSection): maybeSection is Section {
  return Boolean(maybeSection);
}

export function useOnboardingSections(user: User, activeSectionId?: string) {
  const notaryProfile = user.notaryProfile!;
  const migrateExpiringNotaries = notaryProfile.usState.proofCertEnabled;
  const { sections, activeSection } = useMemo(() => {
    const lookup = new Set(notaryProfile.requirements);
    const sections = Object.freeze(
      [
        personalInfoSection(lookup, user),
        commissionDetailsSection(lookup, notaryProfile),
        insuranceDetailsSection(lookup, notaryProfile),
        certificateSection(lookup, notaryProfile, migrateExpiringNotaries),
        stateEducationSection(lookup, notaryProfile),
        sigAndSealSection(lookup, notaryProfile),
        payoutSection(user),
        identityVerificationSection(notaryProfile, migrateExpiringNotaries),
        proofCertificateSection(notaryProfile, migrateExpiringNotaries),
      ]
        .filter(filterSections)
        .map((section) =>
          Object.freeze({
            ...section,
            isActive: activeSectionId === section.id,
          }),
        ),
    );
    const activeSection = sections.find((section) => section.isActive);
    return { sections, activeSection };
  }, [activeSectionId, user, notaryProfile]);
  return {
    sections,
    activeSection,
  };
}

function renderContent({ activeStep, user, onNext, renderUnknownStep, refetch }: StepProps) {
  switch (activeStep?.id) {
    case "PersonalInfo":
      return (
        <PersonalInformationSection
          user={user}
          onNext={onNext}
          renderFooter={(handleSubmit) => <Footer onSubmit={handleSubmit} workToCommit />}
        />
      );
    case "CommissionDetails":
      return (
        <CommissionDetails
          user={user}
          onNext={onNext}
          countyRequired={activeStep.countyRequired}
          notaryIdRequired={activeStep.notaryIdRequired}
          renderFooter={(handleSubmit) => <Footer onSubmit={handleSubmit} workToCommit />}
        />
      );
    case "InsuranceDetails":
      return (
        <InsuranceDetails
          user={user}
          onNext={onNext}
          renderFooter={(handleSubmit) => <Footer onSubmit={handleSubmit} workToCommit />}
        />
      );
    case "DigitalCertificate":
      return (
        <DigitalCertificate
          user={user}
          onNext={onNext}
          renderFooter={(handleSubmit) => <Footer onSubmit={handleSubmit} workToCommit />}
        />
      );
    case "SignatureAndSeal":
      return (
        <SignatureAndSeal
          user={user}
          onNext={onNext}
          renderFooter={(handleSubmit) => <Footer onSubmit={handleSubmit} workToCommit />}
        />
      );
    case "StateEducation":
      return (
        <StateRequiredEducation
          user={user}
          onNext={onNext}
          proofRequired={activeStep.proofRequired}
          expirationDateRequired={activeStep.expirationDateRequired}
          renderFooter={(handleSubmit) => <Footer onSubmit={handleSubmit} workToCommit />}
        />
      );
    case "PayoutOptions":
      return (
        <PayoutOptions
          user={user}
          renderFooter={(disabled) => <Footer onSubmit={onNext} disabled={disabled} workToCommit />}
          refetch={refetch}
        />
      );
    case "IdentityVerification":
      return (
        <IdentityVerification
          user={user}
          renderFooter={(disabled) => <Footer onSubmit={onNext} disabled={disabled} workToCommit />}
        />
      );
    case "ProofCertificate":
      return (
        <ProofCertificate
          user={user}
          renderFooter={(disabled) => <Footer onSubmit={onNext} disabled={disabled} workToCommit />}
        />
      );
    default:
      return renderUnknownStep();
  }
}

export const NotaryOnboardingStep = memo((props: StepProps) => (
  <div className="NotaryProfileWizardStep">{renderContent(props)}</div>
));
