import { FormattedMessage } from "react-intl";

import { NotaryComplianceStatuses } from "graphql_globals";
import { usePermissions } from "common/core/current_user_role";

import { BusinessOnboardingHowToItem, SendHowToItem, EasylinkLinkItem } from "./common";
import { NSTRoot } from "../common/root";
import { DeprecatedHeader } from "../common/header";
import { NSTSection, TaskSection } from "../common/section";
import { SetupNotaryProfileTaskItem } from "../common/task_item";
import { NotaryKnowledgeHowToItem, NotaryToolsHowToItem } from "../common/how_to_item";
import { HowItWorksLinkItem, FaqLinkItem } from "../common/link_item";
import type { BusinessGetStarted_viewer_user_notaryProfile as NotaryProfile } from "./business_get_started_query.graphql";
import ProofCertificatePromo from "./proof_certificate_promo";

type Props = {
  name: string;
  notaryProfile: NotaryProfile | null;
  canInviteNotary: boolean;
};

export function BusinessMemberGetStarted({ name, canInviteNotary, notaryProfile }: Props) {
  const { hasPermissionFor } = usePermissions();
  const canCreateEasyLinks = hasPermissionFor("createEasyLinks");

  return (
    <NSTRoot>
      <DeprecatedHeader name={name} />

      <TaskSection
        title={
          <FormattedMessage
            id="67d4c7ef-c218-41f8-9722-4ea0f1ab3d1b"
            defaultMessage="Finish setting up your account"
          />
        }
      >
        {notaryProfile && notaryProfile.complianceStatus !== NotaryComplianceStatuses.COMPLIANT && (
          <SetupNotaryProfileTaskItem complete={false} notaryProfile={notaryProfile} />
        )}
      </TaskSection>
      {notaryProfile && <ProofCertificatePromo notaryProfile={notaryProfile} showCTA />}
      <NSTSection
        title={
          <FormattedMessage
            id="099ec05a-ff85-4dbe-8651-22e60515ff10"
            defaultMessage="Proof resources"
          />
        }
      >
        <BusinessOnboardingHowToItem />
        {canInviteNotary && <NotaryKnowledgeHowToItem />}
        {notaryProfile?.trainingEnabled && <NotaryToolsHowToItem notaryProfile={notaryProfile} />}
        {!canInviteNotary && <SendHowToItem />}
      </NSTSection>
      <NSTSection
        title={
          <FormattedMessage
            id="887753dc-c75f-4150-88f7-1c11fadce7a9"
            defaultMessage="Other helpful links"
          />
        }
      >
        <HowItWorksLinkItem />
        {canCreateEasyLinks && <EasylinkLinkItem />}
        <FaqLinkItem />
      </NSTSection>
    </NSTRoot>
  );
}
