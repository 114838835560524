import "./index.scss";

import { useCallback, type ComponentProps, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useIntl } from "react-intl";

import { useQuery, useMutation } from "util/graphql";
import { captureException } from "util/exception";
import LoadingIndicator from "common/core/loading_indicator";
import { segmentTrack } from "util/segment";
import FinishedOnboarding from "common/notary/profile_wizard/section/finished";
import { userFullName } from "util/user";
import { isIndependentNotary } from "common/notary/capacity";
import { useA11y } from "common/accessibility";
import { useDocumentTitles } from "util/document_title";
import { useOnboardingV2 } from "util/feature_detection";

import { NotaryOnboardingStep, useOnboardingSections } from "./step";
import Sidebar from "./sidebar";
import OnboardingWelcome from "./welcome";
import OnboardingWelcomeV2 from "./welcome/v2";
import OnboardingOverview from "./overview";
import Verify from "./verify";
import NotaryOnboardingQuery, {
  type NotaryOnboarding_viewer_user as User,
} from "./index_query.graphql";
import UpdateNotaryProfileMutation, {
  type UpdateNotaryProfileVariables,
} from "./update_notary_profile_mutation.graphql";
import NotaryMfaSetup from "./mfa";

type Props = {
  user: User;
  stepId?: string;
  refetch: () => void;
};

type UpdateProfileInput = Omit<UpdateNotaryProfileVariables["input"], "id">;
export type MaybeUpdateUserFn = (input?: UpdateProfileInput | null) => Promise<User>;

function useUpdateNotaryCb(user: User) {
  const updateNotaryProfileMutateFn = useMutation(UpdateNotaryProfileMutation);
  return useCallback<ComponentProps<typeof NotaryOnboardingStep>["onNext"]>(
    async (input) => {
      let updatedUser = user;
      if (input) {
        const response = await updateNotaryProfileMutateFn({
          variables: {
            input: {
              ...input,
              id: user.notaryProfile!.id,
            },
          },
        });
        updatedUser = response.data!.updateNotaryProfile!.currentUser!;
      }
      return updatedUser;
    },
    [updateNotaryProfileMutateFn],
  );
}

function NotaryProfileWizardOnboarding({ stepId, user, refetch }: Props) {
  const intl = useIntl();
  useA11y().useDocumentEntitler({
    title: intl.formatMessage(useDocumentTitles().notaryOnboarding),
  });
  const navigate = useNavigate();
  const isOdnorNst = isIndependentNotary(user.notaryProfile);
  const { sections, activeSection } = useOnboardingSections(user, stepId);
  const updateNotaryUser = useUpdateNotaryCb(user);
  const handleNext = useCallback<ComponentProps<typeof NotaryOnboardingStep>["onNext"]>(
    async (formValues, skipConfig?: "DontNavigate") => {
      const activeSectionIndex = sections.findIndex((section) => section.isActive);
      // Assume that if we can't find the current active step, we should just go to first
      // managed step.
      const newStepId: string | undefined =
        activeSectionIndex === -1 ? sections[0].id : sections[activeSectionIndex + 1]?.id;
      let updatedUser;
      try {
        updatedUser = await updateNotaryUser(formValues);
      } catch (error) {
        segmentTrack("NOTARY_ONBOARDING_ERROR", {
          notary_profile_id: user.notaryProfile?.id,
          section: activeSection?.id,
          created_at: Date.now(),
          portal: "business",
          error: (error as Error).message,
        });
        throw error;
      }
      segmentTrack("NOTARY_ONBOARDING_PAGE_CONTINUE", {
        notary_profile_id: user.notaryProfile?.id,
        section: activeSection?.id,
        created_at: Date.now(),
        portal: "notary",
      });
      if (skipConfig !== "DontNavigate") {
        const path = newStepId
          ? `/settings/notary/onboarding/${newStepId}`
          : "/settings/notary/onboarding/Finished";
        navigate(path);
      }
      return updatedUser;
    },
    [updateNotaryUser, navigate, sections],
  );
  const onboardingV2 = useOnboardingV2();
  const renderUnknownStep = useCallback(() => {
    if (onboardingV2 && stepId === "overview") {
      return <OnboardingOverview steps={sections} />;
    } else if (stepId === "Finished") {
      return <FinishedOnboarding notaryProfile={user.notaryProfile} includeContinue />;
    } else if (stepId) {
      captureException(new Error(`Unknown step id ${stepId}`));
    }
    const handleNextV2 = () => {
      navigate("/settings/notary/onboarding/overview");
    };
    return onboardingV2 ? (
      <OnboardingWelcomeV2
        usStateName={user.notaryProfile?.usState.name}
        independentNotary={isOdnorNst}
        onNext={handleNextV2}
      />
    ) : (
      <OnboardingWelcome
        usStateName={user.notaryProfile?.usState.name}
        independentNotary={isOdnorNst}
        onNext={handleNext}
      />
    );
  }, [stepId, handleNext]);
  const showSidebar = !onboardingV2 || (stepId && stepId !== "overview");
  return (
    <div
      className={
        showSidebar ? "NotaryProfileWizardOnboarding" : "NotaryProfileWizardOnboardingOverview"
      }
    >
      {showSidebar && <Sidebar steps={sections} fullName={userFullName(user)} />}
      <NotaryOnboardingStep
        user={user}
        activeStep={activeSection}
        onNext={handleNext}
        renderUnknownStep={renderUnknownStep}
        refetch={refetch}
      />
    </div>
  );
}

function useResetScroll() {
  const { pathname } = useLocation();
  useEffect(() => {
    const { body } = document;
    let current = document.querySelector(".NotaryProfileWizardOnboarding");
    while (current && current !== body) {
      if (current.scrollTop > 0) {
        current.scrollTop = 0;
        break;
      }
      current = current.parentElement;
    }
  }, [pathname]);
}

function NotaryProfileWizardOnboardingQuery() {
  const { stepId } = useParams();
  const { data, loading, refetch } = useQuery(NotaryOnboardingQuery);
  useResetScroll();

  if (loading) {
    return <LoadingIndicator />;
  }

  const user = data!.viewer.user!;
  const mfaEnabled = Boolean(user.authenticationRequirements.length);
  const isOdnorNst = isIndependentNotary(user.notaryProfile);

  if (user.needsVerification) {
    return <Verify email={user.pendingEmail!} />;
  }
  if (!mfaEnabled && isOdnorNst) {
    return <NotaryMfaSetup refetch={refetch} />;
  }

  return <NotaryProfileWizardOnboarding user={user} stepId={stepId} refetch={refetch} />;
}

export default NotaryProfileWizardOnboardingQuery;
