import { useIntl } from "react-intl";
import { Navigate } from "react-router-dom";

import { Feature } from "graphql_globals";
import LoadingIndicator from "common/core/loading_indicator";
import { useQuery } from "util/graphql";
import { useActiveOrganization } from "common/account/active_organization";
import { isNotaryNST } from "common/notary/capacity";
import { useA11y } from "common/accessibility";
import { usePermissions } from "common/core/current_user_role";
import { useDocumentTitles } from "util/document_title";
import { useFeatureFlag } from "common/feature_gating";
import { TRANSACTION_PATH } from "util/routes";
import { HIDE_GET_STARTED } from "constants/feature_gates";

import { Root } from "../common/root";
import { Header } from "../common/header";
import { ToolsSection, BusinessWorkflows, ResourcesSection } from "../common/section";
import NotaryIndividualContractorGetStarted from "./notary_individual_contractor";
import { BusinessMemberGetStarted } from "./member";
import { BusinessAdminGetStarted } from "./admin";
import BusinessGetStartedQuery, {
  type BusinessGetStarted_viewer as Viewer,
  type BusinessGetStarted_organization_Organization as Organization,
} from "./business_get_started_query.graphql";
import { MobileDeviceGetStarted } from "./mobile_device";

type Props = {
  viewer: Viewer;
  organization: Organization | null;
};

function GetStartedV2Content({ organization }: { organization: Organization }) {
  const featureList = organization.featureList;

  return (
    <div>
      <Header organizationId={organization.id} />
      <Root>
        <BusinessWorkflows />
        <ToolsSection featureList={featureList} />
        <ResourcesSection />
      </Root>
    </div>
  );
}

function LoadedBusinessGetStarted({ viewer, organization }: Props) {
  const user = viewer.user!;
  const { notaryProfile, firstName } = user;
  const isNst = isNotaryNST(notaryProfile);
  const { hasPermissionFor } = usePermissions();
  const canViewAdminGetStarted =
    hasPermissionFor("editOrganizationDetails") || hasPermissionFor("editTeamMember");

  const getStartedV2Enabled = useFeatureFlag("get-started-v2");

  if (!organization) {
    return <Navigate to="/" />;
  }

  if (isNst) {
    return <NotaryIndividualContractorGetStarted organization={organization} viewer={viewer} />;
  }

  if (getStartedV2Enabled) {
    return <GetStartedV2Content organization={organization} />;
  }

  const canInviteNotary = organization.featureList.includes(Feature.ORGANIZATION_NOTARIES);

  if (canViewAdminGetStarted) {
    const { employeeLimit } = organization.activeTier.options;
    const canManageTeam = organization.featureList.includes(Feature.TEAM_MANAGEMENT);
    const hasSeats =
      employeeLimit === null
        ? organization.memberships.totalCount < 3
        : employeeLimit - organization.memberships.totalCount > 0;

    return (
      <BusinessAdminGetStarted
        name={firstName!}
        hasSeats={hasSeats}
        hasLogo={Boolean(organization.logoUrl)}
        notaryProfile={notaryProfile}
        billingSetup={organization.paymentSpecified!}
        canInviteNotary={canInviteNotary}
        canManageTeam={canManageTeam}
      />
    );
  }

  return (
    <BusinessMemberGetStarted
      name={firstName!}
      notaryProfile={notaryProfile}
      canInviteNotary={canInviteNotary}
    />
  );
}

function DesktopGetStarted() {
  const [activeOrganizationId] = useActiveOrganization();
  const hideGetStarted = useFeatureFlag(HIDE_GET_STARTED);

  const { data, loading } = useQuery(BusinessGetStartedQuery, {
    variables: {
      orgId: activeOrganizationId || "skip",
      includeOrg: Boolean(activeOrganizationId),
    },
  });

  if (hideGetStarted || !activeOrganizationId) {
    return <Navigate to={TRANSACTION_PATH} replace />;
  }

  if (loading) {
    return <LoadingIndicator />;
  }

  const { organization, viewer } = data!;

  if (organization && organization.__typename !== "Organization") {
    throw new Error(`Expected organization, found ${organization.__typename}`);
  }

  return <LoadedBusinessGetStarted viewer={viewer} organization={organization} />;
}

export function GetStarted({ showMobileDeviceContent }: { showMobileDeviceContent: boolean }) {
  const intl = useIntl();
  useA11y().useDocumentEntitler({ title: intl.formatMessage(useDocumentTitles().getStarted) });
  return showMobileDeviceContent ? <MobileDeviceGetStarted /> : <DesktopGetStarted />;
}
