import { memo } from "react";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";

import { SealLogo } from "common/core/logo";
import { Heading, Paragraph } from "common/core/typography";
import Link from "common/core/link";
import { Card } from "common/core/card";
import Icon from "common/core/icon";
import Button from "common/core/button";

import Styles from "./index.module.scss";
import { STEP_CONTENT, type Step } from "../sidebar";

type Props = {
  steps: readonly Step[];
};

type RenderStepsProps = {
  steps: readonly Step[];
  firstIncompleteStepId?: keyof typeof STEP_CONTENT;
  stepCounter: number;
};

function RenderSteps({ steps, firstIncompleteStepId, stepCounter }: RenderStepsProps) {
  const navigate = useNavigate();

  return (
    <ol className={Styles.stepsList}>
      {steps.map(({ id, completed }, index) => {
        const content = STEP_CONTENT[id];
        const isNextIncompleteStep = id === firstIncompleteStepId;

        const cx = classNames(Styles.stepItem, {
          [Styles.completed]: completed,
          [Styles.nextIncomplete]: isNextIncompleteStep,
        });

        return (
          <li key={id} className={cx}>
            <span className={Styles.circleContent}>
              {completed ? (
                <Icon className={Styles.checkmark} name="tick" />
              ) : (
                <span className={Styles.stepNumber}>{stepCounter + index}</span>
              )}
            </span>
            <div className={Styles.stepContent}>
              {completed ? (
                <Link
                  underlined={false}
                  black
                  to={`/settings/notary/onboarding/${id}`}
                  className={Styles.link}
                >
                  {content}
                </Link>
              ) : isNextIncompleteStep ? (
                <div className={Styles.nextIncompleteStep}>
                  {content}
                  <Button
                    buttonColor="action"
                    variant="primary"
                    onClick={() => navigate(`/settings/notary/onboarding/${id}`)}
                    className={Styles.nextIncompleteButton}
                  >
                    <FormattedMessage
                      id="01a224a6-3385-4de4-be11-428fe70b8aa5"
                      defaultMessage="Begin"
                    />
                  </Button>
                </div>
              ) : (
                <span className={Styles.incompleteStep}>{content}</span>
              )}
            </div>
          </li>
        );
      })}
    </ol>
  );
}

function OnboardingOverview({ steps }: Props) {
  const firstIncompleteStep = steps.find((step) => !step.completed);
  const firstIncompleteStepId = firstIncompleteStep?.id;
  const filterSteps = (steps: readonly Step[], ids: (keyof typeof STEP_CONTENT)[]) =>
    steps.filter((step) => ids.includes(step.id));
  const profileSteps = filterSteps(steps, [
    "PersonalInfo",
    "CommissionDetails",
    "InsuranceDetails",
    "DigitalCertificate",
    "SignatureAndSeal",
    "PayoutOptions",
  ]);
  const proofCertificateSteps = filterSteps(steps, ["IdentityVerification", "ProofCertificate"]);

  return (
    <div className={Styles.main}>
      <SealLogo className={Styles.logo} />
      <Heading level="h1" textStyle="headingFour" textAlign="left">
        <FormattedMessage
          id="7407b044-56af-47d4-90c9-8dadae50cfe1"
          defaultMessage="Your onboarding process"
        />
      </Heading>
      <Paragraph size="large" className={Styles.paragraph}>
        <FormattedMessage
          id="a52910a1-6e38-49f8-a571-89b74f898737"
          defaultMessage="Complete all steps, and our team will review your profile and email you the next steps. This process takes 2-5 days to maintain platform security. For questions, contact {onboardingLink}"
          values={{
            onboardingLink: (
              <Link href="mailto:notaryonboarding@proof.com">notaryonboarding@proof.com</Link>
            ),
          }}
        />
      </Paragraph>
      <Card>
        <div>
          <Heading level="h2" textStyle="headingFive" textAlign="left">
            <FormattedMessage
              id="1a5c3b5b-5b9d-4b1b-9f7f-3c3d6d7f2b5d"
              defaultMessage="Create your notary profile"
            />
          </Heading>
          <div className={Styles.subHeading}>
            <FormattedMessage
              id="b1d7f3b0-0b6b-4f8b-8f8d-1f4b4d4f9f3e"
              defaultMessage="Provide your personal information and notary details"
            />
          </div>
          <RenderSteps
            steps={profileSteps}
            firstIncompleteStepId={firstIncompleteStepId}
            stepCounter={1}
          />
        </div>
      </Card>
      {proofCertificateSteps.length > 0 && (
        <Card>
          <div>
            <Heading level="h2" textStyle="headingFive" textAlign="left">
              <FormattedMessage
                id="b1d7f3b0-0b6b-4f8b-8f8d-1f4b4d4f9f3e"
                defaultMessage="Generate your Proof Digital Certificate"
              />
            </Heading>
            <div className={Styles.subHeading}>
              <FormattedMessage
                id="28fac965-f2f5-487f-b5ea-1ae8590a8bd1"
                defaultMessage="Your certificate ensures secure transactions on the platform"
              />
            </div>
            <RenderSteps
              steps={proofCertificateSteps}
              firstIncompleteStepId={firstIncompleteStepId}
              stepCounter={profileSteps.length + 1}
            />
          </div>
        </Card>
      )}
      <Card>
        <div>
          <Heading level="h2" textStyle="headingFive" textAlign="left">
            <FormattedMessage
              id="f93721fd-71f0-45c9-8f7e-0b21608032ac"
              defaultMessage="Submit profile for review by Proof"
            />
          </Heading>
          <div className={Styles.subHeading}>
            <FormattedMessage
              id="1b54cf58-50ae-4ef2-8fdf-956737946be1"
              defaultMessage="Please allow 2-5 business days for initial review by Proof "
            />
          </div>
          {
            // todo: nx-4440 add submit profile steps as part of sidebar updates
          }
        </div>
      </Card>
    </div>
  );
}

export default memo(OnboardingOverview);
