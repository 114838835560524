import { useCallback, useState } from "react";
import { FormattedMessage } from "react-intl";

import { Feature, type Language } from "graphql_globals";
import { useMutation } from "util/graphql";
import { isGraphQLError } from "util/graphql/query";
import { useForm } from "common/core/form";
import {
  AddEditModalInner,
  type AddEditModalFormValues,
  type InnerProps,
} from "common/organization/member_management/add_edit_modal";
import Button from "common/core/button";
import { Hr } from "common/core/horizontal_rule";
import { pushNotification } from "common/core/notification_center/actions";
import { NOTIFICATION_SUBTYPES, NOTIFICATION_TYPES } from "constants/notifications";
import { segmentTrack } from "util/segment";
import { SEGMENT_EVENTS } from "constants/analytics";

import CreateOrganizationMembershipMutation from "./create_organization_membership.graphql";
import CreateOrganizationNotaryMutation from "./create_organization_notary.graphql";
import Styles from "./updated_onboarding.module.scss";
import {
  type Slideshow_viewer_user_organization as Organization,
  type Slideshow_viewer_notaryUsStates as NotaryUsStates,
} from "../slideshow_query.graphql";

function isValidLangs(langs: AddEditModalFormValues["notaryLangs"]): boolean {
  return langs.some(Boolean);
}

function serializeLang(formLang: Language | false): formLang is Language {
  return Boolean(formLang);
}

function InviteModalInner({
  possibleNotaryStates,
  assignableRoles,
  organizationId,
  onNext,
}: InnerProps & { organizationId: string; onNext: () => void }) {
  const createOrganizationMembershipMutateFn = useMutation(CreateOrganizationMembershipMutation);
  const createOrganizationNotaryMutateFn = useMutation(CreateOrganizationNotaryMutation);
  const [isLoading, setIsLoading] = useState(false);
  const [mutationError, setMutationError] = useState<string | null>(null);

  const form = useForm<AddEditModalFormValues>({
    mode: "onSubmit",
    defaultValues: {
      roleSchedules: assignableRoles.find((role) => role.displayName === "Team Member")?.id,
    },
  });
  const [notaryLangsFormValue] = form.watch(["notaryLangs"]);

  const handleMutationErrors = useCallback((e: Error) => {
    const isMutationGraphError = isGraphQLError(e);
    if (isMutationGraphError && e.message.includes("employee_limit_reached")) {
      setMutationError("limit-reached-error");
    } else if (
      isMutationGraphError &&
      (e.message.includes("membership_already_exists") ||
        e.message.includes("email_already_registered"))
    ) {
      setMutationError("already-exists-error");
    } else if (isMutationGraphError && e.message.includes("membership_already_in_org")) {
      setMutationError("already-member-in-org-error");
    } else {
      pushNotification({
        type: NOTIFICATION_TYPES.DEFAULT,
        subtype: NOTIFICATION_SUBTYPES.ERROR,
        message: (
          <FormattedMessage
            id="317676dc-6ef8-43bb-8c90-254e0c419fb7"
            defaultMessage="We're sorry, something went wrong. Please try again."
          />
        ),
        position: "topCenter",
      });
    }
    setIsLoading(false);
  }, []);

  const handleInvite = useCallback((values: AddEditModalFormValues) => {
    const { firstName, lastName, roleSchedules, email } = values;
    const withNotaryProfile = values.isNotary;
    const newRoleSchedules = [{ userRoleId: roleSchedules }];

    const createOrgNotaryPromise: Promise<unknown> = withNotaryProfile
      ? createOrganizationNotaryMutateFn({
          variables: {
            input: {
              firstName,
              lastName,
              organizationId,
              email,
              roleSchedules: newRoleSchedules,
              languages: values.notaryLangs.filter(serializeLang),
              usStateId: values.notaryState,
            },
          },
        })
      : Promise.resolve();

    return createOrgNotaryPromise
      .then(() => {
        if (!withNotaryProfile) {
          const newRoleSchedules = [{ userRoleId: roleSchedules }];
          const input = {
            firstName,
            lastName,
            organizationId,
            email,
            reason: values.reason,
            roleSchedules: newRoleSchedules,
          };
          return createOrganizationMembershipMutateFn({
            variables: {
              mutationInput: input,
            },
          });
        }
      })
      .then(() => {
        segmentTrack(SEGMENT_EVENTS.MEMBER_INVITED, {
          source: "Business Onboarding Modal",
          invitee_email: email,
          firstName,
          lastName,
          member_role: roleSchedules,
        });
        onNext();
      })
      .catch(handleMutationErrors);
  }, []);

  return (
    <>
      <div className={Styles.modalContent}>
        <AddEditModalInner
          possibleNotaryStates={possibleNotaryStates}
          canDisableOwner={false}
          requireReason={false}
          assignableRoles={assignableRoles}
          validationError={mutationError}
          form={form}
        />
      </div>
      <Hr />
      <div className={Styles.buttons}>
        <Button
          key="skip"
          buttonColor="action"
          variant="secondary"
          automationId="invite-member-modal-skip-btn"
          onClick={() => {
            segmentTrack(SEGMENT_EVENTS.BUSINESS_ONBOARDING_INVITE_SKIPPED);
            onNext();
          }}
        >
          <FormattedMessage id="9e185784-919b-404c-bb50-cdf0cbf751ef" defaultMessage="Skip" />
        </Button>
        <Button
          key="confirm"
          buttonColor="action"
          variant="primary"
          isLoading={isLoading}
          automationId="invite-member-modal-primary-btn"
          onClick={form.handleSubmit((formValues) => {
            if (formValues.isNotary && !isValidLangs(notaryLangsFormValue)) {
              return;
            }
            setIsLoading(true);
            handleInvite(formValues).catch(() => {
              setIsLoading(false);
            });
          })}
        >
          <FormattedMessage
            id="9e185784-919b-404c-bb50-cdf0cbf751ef"
            defaultMessage="Invite and continue"
          />
        </Button>
      </div>
    </>
  );
}

export function InviteModal({
  organization,
  notaryUsStates,
  onNext,
}: {
  organization: Organization;
  notaryUsStates: (NotaryUsStates | null)[];
  onNext: () => void;
}) {
  const filteredNotaryUsStates = organization.featureList.includes(Feature.ORGANIZATION_NOTARIES)
    ? (notaryUsStates.filter(Boolean) as { value: string; label: string }[])
    : [];

  return (
    <InviteModalInner
      possibleNotaryStates={filteredNotaryUsStates}
      canDisableOwner={false}
      requireReason={false}
      assignableRoles={organization.assignableRoles.filter(
        (role) => role.name === "admin" || role.name === "employee",
      )}
      organizationId={organization.id}
      onNext={onNext}
    />
  );
}
