import { useState, useEffect, type ReactNode, type ComponentProps } from "react";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";

import Svg from "common/core/svg";
import Button from "common/core/button";
import { useMutation } from "util/graphql";
import { useId } from "util/html";
import { useForm } from "common/core/form";
import { TextAreaInput } from "common/core/form/text";
import { FormRow } from "common/core/form/layout";
import { defaultRequiredMessage, FormattedFieldError, isAriaInvalid } from "common/core/form/error";
import ThankYouImage from "assets/images/thank_you_feedback.svg";
import { IconButton } from "common/core/button/icon_button";

import SupportChat from "./chat";
import Styles from "./feedback.module.scss";
import AddUserFeedbackMutation from "./add_user_feedback_mutation.graphql";
import type { ViewerFeedback as Viewer } from "./feedback_fragment.graphql";

type RatingProps = {
  isSubmitting: boolean;
  onClickRating: (rating: number) => void;
};
type FreeFormValues = { feedback: string };
type FreeFormProps = {
  isSubmitting: boolean;
  givenRating: number;
  onSubmit: (formValues: FreeFormValues) => void;
};
type Step = { name: "rating" | "end" | "none" } | { name: "freeform"; rating: number };
type CollectionProps = {
  step: Step;
  onSetStep: (newValue: Step) => void;
};
type ChatWithFeedbackProps = {
  children?: ReactNode;
  viewer: null | (Viewer & ComponentProps<typeof SupportChat>["viewer"]);
};

const RATINGS = Array.from({ length: 5 }).map((_, index) => index + 1);
const MESSAGES = defineMessages({
  feedbackPlaceholder: {
    id: "b148b667-9423-472e-b163-61e806cdb53c",
    defaultMessage: "Please type here...",
  },
  thankyou: {
    id: "62456106-f6e1-45b2-b22b-2523df28dda3",
    defaultMessage: "We appreciate your feedback.",
  },
  dismissLabel: {
    id: "790a23ee-fe75-4026-acc5-e5fcf3e0d6b0",
    defaultMessage: "Close Feedback",
  },
});

function End({ onTimeout }: { onTimeout: () => void }) {
  const intl = useIntl();
  useEffect(() => {
    // WARN: this doesn't use the _latest_ onTimeout func.
    const timeoutId = setTimeout(onTimeout, 10_000);
    return () => clearTimeout(timeoutId);
  }, []);
  return (
    <>
      <FormattedMessage
        id="a81a0b6f-b3ea-4f3e-b2ec-caf718aab3ac"
        defaultMessage="Thank you for your response!"
        tagName="h5"
      />
      <div className={Styles.endMain}>
        <FormattedMessage
          id="00056cb1-73e1-4dea-bcf1-e592bedd798d"
          defaultMessage="Your feedback helps us improve the Notarize product."
          tagName="p"
        />
        <Svg src={ThankYouImage} alt={intl.formatMessage(MESSAGES.thankyou)} />
      </div>
    </>
  );
}

function FreeFormCollection({ onSubmit, givenRating, isSubmitting }: FreeFormProps) {
  const intl = useIntl();
  const form = useForm<FreeFormValues>();
  const { errors } = form.formState;
  return (
    <form onSubmit={form.handleSubmit(onSubmit)}>
      {givenRating >= 4 ? (
        <FormattedMessage
          id="a5d00bad-74ad-4c6e-97a2-e153448c03db"
          defaultMessage="What did you like best about the experience?"
          tagName="h5"
        />
      ) : (
        <FormattedMessage
          id="33aecb6a-fad3-4066-aa78-236750031598"
          defaultMessage="Oh no! What can we do to improve your experience?"
          tagName="h5"
        />
      )}
      <FormRow className={Styles.feedbackTextarea}>
        <TextAreaInput
          aria-invalid={isAriaInvalid(errors.feedback)}
          placeholder={intl.formatMessage(MESSAGES.feedbackPlaceholder)}
          {...form.register("feedback", { required: defaultRequiredMessage(intl) })}
        />
        <FormattedFieldError inputName="feedback" error={errors.feedback} />
      </FormRow>
      <footer className={Styles.formFooter}>
        <Button type="submit" buttonColor="action" variant="primary" isLoading={isSubmitting}>
          <FormattedMessage id="86ce32f7-a565-4f93-9d3c-6fda9ffc94fc" defaultMessage="Send" />
        </Button>
      </footer>
    </form>
  );
}

function RatingCollection({ onClickRating, isSubmitting }: RatingProps) {
  const lowIdReg = useId();
  const highIdReg = useId();
  return (
    <>
      <FormattedMessage
        id="fc7f41b8-8dd6-41ee-b8ae-ddd8365a79b8"
        defaultMessage="How would you rate your experience using Proof?"
        tagName="h5"
      />
      <div className={Styles.ratingMain}>
        <ol className={Styles.numbers}>
          {RATINGS.map((value) => {
            return (
              <li key={value}>
                <button
                  type="button"
                  aria-describedby={
                    value === 1 ? lowIdReg : value === RATINGS.length ? highIdReg : undefined
                  }
                  onClick={() => onClickRating(value)}
                  disabled={isSubmitting}
                >
                  {value}
                </button>
              </li>
            );
          })}
        </ol>
        <div className={Styles.numberMarkers}>
          <span id={lowIdReg}>
            <FormattedMessage
              id="fee8572b-3066-4a8a-bb02-fa4978621058"
              defaultMessage="Very Poor"
            />
          </span>
          <span id={highIdReg}>
            <FormattedMessage
              id="2d31ec5f-50bc-4bfa-832b-17bb38e7061f"
              defaultMessage="Excellent"
            />
          </span>
        </div>
      </div>
    </>
  );
}

function FeedbackCollection({ step, onSetStep }: CollectionProps) {
  const intl = useIntl();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const addUserFeedbackMutateFn = useMutation(AddUserFeedbackMutation);
  return (
    <div className={Styles.container}>
      <div className={Styles.exit}>
        <IconButton
          name="x"
          label={intl.formatMessage(MESSAGES.dismissLabel)}
          buttonColor="dark"
          variant="tertiary"
          buttonSize="condensed"
          automationId="close-support-feedback-module"
          onClick={() => {
            if (step.name !== "end") {
              // Don't persist dismissal if they're already at the end, so our dismissal is a relatively
              // accurate analytic.
              addUserFeedbackMutateFn({ variables: { input: { dismiss: true } } });
            }
            // Dismissal is instant, we do not wait for server.
            onSetStep({ name: "none" });
          }}
        />
      </div>
      {step.name === "rating" ? (
        <RatingCollection
          isSubmitting={isSubmitting}
          onClickRating={(rating) => {
            setIsSubmitting(true);
            addUserFeedbackMutateFn({ variables: { input: { rating } } })
              .then(() => {
                onSetStep({ name: "freeform", rating });
              })
              .finally(() => {
                setIsSubmitting(false);
              });
          }}
        />
      ) : step.name === "freeform" ? (
        <FreeFormCollection
          givenRating={step.rating}
          isSubmitting={isSubmitting}
          onSubmit={({ feedback }) => {
            setIsSubmitting(true);
            addUserFeedbackMutateFn({ variables: { input: { text: feedback } } })
              .then(() => {
                onSetStep({ name: "end" });
              })
              .finally(() => {
                setIsSubmitting(false);
              });
          }}
        />
      ) : step.name === "end" ? (
        <End
          onTimeout={() => {
            onSetStep({ name: "none" });
          }}
        />
      ) : null}
    </div>
  );
}

export function SupportChatWithFeedback({ children, viewer }: ChatWithFeedbackProps) {
  const [chatIsOpen, setChatIsOpen] = useState(false);
  // State is here so we can save even when Feedback is closed due to chat.
  const [feedbackStep, setFeedbackStep] = useState<Step>({ name: "rating" });
  // Never show feedback if chat is open. Only show if the user is primedForFeedback, but also
  // let it stay open while they're on the thank you.
  const showFeedback = Boolean(
    !chatIsOpen &&
      feedbackStep.name !== "none" &&
      (viewer?.user?.primedForFeedback || feedbackStep.name === "end"),
  );
  return (
    <>
      {showFeedback && <FeedbackCollection step={feedbackStep} onSetStep={setFeedbackStep} />}
      <SupportChat viewer={viewer} onToggle={setChatIsOpen}>
        {children}
      </SupportChat>
    </>
  );
}
