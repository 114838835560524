import { FormattedMessage } from "react-intl";
import { useCallback, useEffect, useState } from "react";

import { SLIDESHOW_TAG_V2, type SlideshowProps } from "business_portal/onboarding/slideshow";
import Carousel, { Slide } from "common/modals/carousel";
import TagUserMutationGraph from "common/onboarding_slides/tag_user_mutation.graphql";
import { useMutation } from "util/graphql";
import { OrganizationMembershipRole } from "graphql_globals";
import { segmentTrack } from "util/segment";
import { SEGMENT_EVENTS } from "constants/analytics";

import { InviteModal } from "./slides/invite_modal";
import { PaymentModal } from "./slides/payment_modal";
import { IndustryModal } from "./slides/industry_modal";

function UpdatedOnboardingHeading() {
  return (
    <>
      <FormattedMessage
        id="255d0110-ecf4-4d8d-9fc5-af0bf119dca8"
        defaultMessage="Welcome to Proof for Business!"
        tagName="h1"
      />
      <FormattedMessage
        id="1f4bc590-ae6c-45cb-832e-952c76c1640f"
        defaultMessage="Get your account up and running in three quick steps."
        tagName="p"
      />
    </>
  );
}
type UpdatedSlideshowProps = SlideshowProps & {
  industryOptions: string[];
};

export function SlideshowOnboarding({
  user,
  notaryUsStates,
  children,
  industryOptions,
}: UpdatedSlideshowProps) {
  const isOwner = user.organizationMembership?.role === OrganizationMembershipRole.OWNER;
  const slideshowTags = user.tags?.filter((tag) => tag?.tag === SLIDESHOW_TAG_V2);
  const [onBoardingCardShowed, setOnBoardingCardShowed] = useState(
    Boolean(slideshowTags && slideshowTags.length > 0),
  );
  const visible = isOwner && !onBoardingCardShowed;

  const tagUserMutateFn = useMutation(TagUserMutationGraph);
  const onDismiss = useCallback(() => {
    tagUserMutateFn({
      variables: {
        input: {
          userId: user.id,
          tag: SLIDESHOW_TAG_V2,
        },
        tags: [SLIDESHOW_TAG_V2],
      },
    }).then(({ data }) => {
      if (data!.tagUser!.user!.tags!.length) {
        setOnBoardingCardShowed(true);
      }
    });
  }, [user.id, setOnBoardingCardShowed]);

  useEffect(() => {
    if (visible) {
      segmentTrack(SEGMENT_EVENTS.BUSINESS_ONBOARDING_SHOWN, {
        member_role: user.organizationMembership.role,
        pricing_plan_id: user.activeTier.planId,
      });
    }
  }, [visible]);

  return (
    <>
      {visible && (
        <Carousel numberOfSlides={3} disableNavigation onFinalSlideNext={onDismiss} useV2styling>
          {({ forceNext }) => (
            <>
              <Slide
                useV2styling
                v2Heading={<UpdatedOnboardingHeading />}
                v2StepLabel={
                  <FormattedMessage
                    id="b52e4c18-6483-49d0-a33d-f067a49b5af3"
                    defaultMessage="Industry information"
                  />
                }
              >
                <IndustryModal
                  onNext={forceNext}
                  industryOptions={industryOptions}
                  organization={user.organization!}
                />
              </Slide>
              <Slide
                useV2styling
                v2Heading={<UpdatedOnboardingHeading />}
                v2StepLabel={
                  <FormattedMessage
                    id="12d15267-886c-4720-ad6e-155f88621478"
                    defaultMessage="Invite a team member to your account"
                  />
                }
              >
                <InviteModal
                  organization={user.organization!}
                  notaryUsStates={notaryUsStates}
                  onNext={forceNext}
                />
              </Slide>
              <Slide
                useV2styling
                v2Heading={<UpdatedOnboardingHeading />}
                v2StepLabel={
                  <FormattedMessage
                    id="06c9cf1d-dc00-4c64-9beb-cdeb45c10714"
                    defaultMessage="Set up payment"
                  />
                }
              >
                <PaymentModal organization={user.organization!} onNext={forceNext} />
              </Slide>
            </>
          )}
        </Carousel>
      )}
      {children}
    </>
  );
}
