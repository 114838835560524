import { memo } from "react";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";
import { useNavigate } from "react-router-dom";

import RequestOnDemandCapacityApprovalMutation from "common/notary/capacity/request_on_demand_capacity_approval_mutation.graphql";
import {
  NotaryCapacityType,
  NotaryActivationStatus,
  NotaryProfileInvalidFields,
} from "graphql_globals";
import Button from "common/core/button";
import Icon from "common/core/icon";
import OnDemandQueue from "assets/images/notarize-illustrations/on_demand_queue.svg";
import { useRawMutation } from "util/graphql/mutation";
import { redirectToSubdomain } from "util/application_redirect";
import AppSubdomains from "constants/app_subdomains";
import { useIAnav } from "util/feature_detection";

import Styles from "./join_nod_panel.module.scss";

type NotaryProfile = {
  id: string;
  trainingEnabled: boolean;
  capacities: {
    type: NotaryCapacityType;
    status: NotaryActivationStatus;
    validation: {
      invalidFields: NotaryProfileInvalidFields[];
    };
  }[];
};

const MESSAGES = defineMessages({
  altOnDemand: { id: "a068bc9f-bf0e-49cb-a291-9ed248d1871b", defaultMessage: "On Demand Queue" },
});
const UPDATE_NNA_DOC = (
  <FormattedMessage
    id="645218ff-e934-4cd6-b35d-18dd2fb5e99b"
    defaultMessage="Update NNA documentation"
  />
);

function JoinNodPanel({ notaryProfile }: { notaryProfile: NotaryProfile }) {
  const intl = useIntl();
  const navigate = useNavigate();
  const iaNav = useIAnav();
  const handleUploadNNADocumentation = () =>
    iaNav
      ? redirectToSubdomain(AppSubdomains.customer, "/settings/notary/profile/commission", {
          newTab: true,
        })
      : navigate("/settings/notary/profile/CommissionAndRonInfo");
  const [requestOnDemandCapacityApprovalMutateFn, { loading: onDemandCapacityApprovalLoading }] =
    useRawMutation(RequestOnDemandCapacityApprovalMutation);
  const onDemandCapacity = notaryProfile.capacities.find(
    (c) => c.type === NotaryCapacityType.ON_DEMAND,
  )!;
  const isInvited = onDemandCapacity.status === NotaryActivationStatus.INVITED;
  const nnaInfoProvided = !onDemandCapacity.validation.invalidFields.some(
    (f) =>
      f === NotaryProfileInvalidFields.INVALID_BACKGROUND_CHECK_EXPIRY ||
      f === NotaryProfileInvalidFields.INVALID_NNA_TRAINING_EXPIRY,
  );
  return (
    <div className={Styles.panel}>
      <img src={OnDemandQueue} alt={intl.formatMessage(MESSAGES.altOnDemand)} />
      <p>
        <FormattedMessage
          id="0598895c-fd7d-425d-a2e2-cbeb614e6665"
          defaultMessage="Join the on-demand notary network"
          tagName="strong"
        />
      </p>
      <FormattedMessage
        id="538ff212-76e7-4e57-82f4-1825cd4c3ccc"
        defaultMessage="Complete the steps below to join our network of 1,000+ independent notaries and connect directly with signers."
        tagName="p"
      />
      {!nnaInfoProvided && !isInvited && (
        <p className={Styles.missingNna}>
          <FormattedMessage
            id="b63cf30d-1840-458d-a35d-9bc6cbb573c8"
            defaultMessage="Your NNA info became invalid. Update this to access the queue."
          />
        </p>
      )}
      {nnaInfoProvided ? (
        <div className={Styles.completed}>
          <Icon name="success" />
          {UPDATE_NNA_DOC}
        </div>
      ) : (
        <Button variant="secondary" buttonColor="action" onClick={handleUploadNNADocumentation}>
          {UPDATE_NNA_DOC}
        </Button>
      )}
      {isInvited ? (
        <Button
          variant="secondary"
          buttonColor="action"
          isLoading={onDemandCapacityApprovalLoading}
          disabled={!nnaInfoProvided}
          onClick={() => {
            requestOnDemandCapacityApprovalMutateFn({ variables: { input: {} } });
          }}
        >
          <FormattedMessage
            id="e32f6c65-9fc9-4b32-8a35-e019e458eab7"
            defaultMessage="Begin on-demand training"
          />
        </Button>
      ) : notaryProfile.trainingEnabled ? (
        <div className={Styles.completed}>
          <Icon name="success" />
          <FormattedMessage
            id="a7c466b7-3165-4ded-a3bc-97bf99f2477d"
            defaultMessage="Training in-progress. Check your email for next steps"
          />
        </div>
      ) : null}
    </div>
  );
}

export default memo(JoinNodPanel);
