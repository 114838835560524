import type { ReactNode } from "react";
import { FormattedMessage } from "react-intl";

import { useViewer } from "util/viewer_wrapper";
import Button from "common/core/button";
import WorkflowModal from "common/modals/workflow_modal";
import { hardNavigateTo } from "util/navigation";
import AppSubdomains from "constants/app_subdomains";
import { redirectUrl } from "util/application_redirect";

import { isIndependentNotary } from "./capacity";
import type { NotaryMfaDisabled as Viewer } from "./notary_mfa_disabled.graphql";

function NotaryMfaBlocker({ children }: { children: ReactNode }) {
  const { viewer } = useViewer<Viewer>();
  const { notaryProfile, authenticationRequirements } = viewer.user!;
  const mfaEnabled = Boolean(authenticationRequirements.length);
  const isOdnorNst = Boolean(notaryProfile && isIndependentNotary(notaryProfile));
  const close = () =>
    hardNavigateTo(
      redirectUrl(AppSubdomains.customer, "/settings/profile/overview?scrollToSecurityForm=true"),
    );
  return (
    <>
      {isOdnorNst && !mfaEnabled && (
        <WorkflowModal
          title={
            <FormattedMessage
              id="2fb77c80-42f1-4272-9cb8-7ab7110c6e3f"
              defaultMessage="Secure your account"
            />
          }
          buttons={[
            <Button buttonColor="action" variant="primary" key="confirm-publish" onClick={close}>
              <FormattedMessage
                id="8f7e6783-685f-4cbc-9457-eb9389fb84ad"
                defaultMessage="Settings"
              />
            </Button>,
          ]}
        >
          <p>
            <FormattedMessage
              id="436be95c-576b-4490-8e7a-5aec5e2fb5da"
              defaultMessage="Multi-factor authentication (MFA) is required for your account for additional security."
            />
          </p>
        </WorkflowModal>
      )}
      {children}
    </>
  );
}

export default NotaryMfaBlocker;
