import request from "util/request";
import { hardNavigateToUnsafe } from "util/navigation";

type AsyncJobData = {
  gid: string;
  name: string;
  status: string;
  subject_gid: string;
  subject_type: string;
};

function getAuthorizationUrl() {
  return request("get", "dotloop/authorization_url", {}).then((json) => {
    return json.url;
  });
}

export function triggerAuthorizationFlow() {
  return getAuthorizationUrl().then(hardNavigateToUnsafe);
}

function withReAuthorization<Args extends unknown[], R>(fn: (...args: Args) => Promise<R>) {
  function wrapper(...args: Args) {
    return fn(...args).catch((e) => {
      if (e.body?.error === "needs_authorization") {
        triggerAuthorizationFlow();
      }
    });
  }
  return wrapper;
}

export const getProfiles = withReAuthorization(() => {
  return request("get", "dotloop/profiles", {}).then((json) => {
    return json.profiles;
  });
});

export const getLoops = withReAuthorization((profileId: number) => {
  return request("get", `dotloop/${profileId}/loops`, {}).then((json) => {
    return json.loops;
  });
});

export const getDocuments = withReAuthorization((profileId: number, loopId: number) => {
  return request("get", `dotloop/${profileId}/loops/${loopId}/documents`, {}).then((json) => {
    return json.documents;
  });
});

export const getParticipants = withReAuthorization((profileId: number, loopId: number) => {
  return request("get", `dotloop/${profileId}/loops/${loopId}/participants`, {}).then((json) => {
    return json.participants;
  });
});

export function getAsyncJob(asyncJobId: string): Promise<AsyncJobData> {
  return request("get", `jobs/${asyncJobId}`, {}).then((json) => {
    return json.data;
  });
}

export function postImportLoop(
  profileId: number,
  loopId: number,
  documentIds: number[],
  participantIds: number[],
) {
  const params = {
    loop_id: loopId,
    profile_id: profileId,
    document_ids: documentIds,
    participant_ids: participantIds,
  };
  return request("post", "dotloop/import_loop", params).then((json) => {
    return json.data;
  });
}
