import "./index.scss";

import { memo, useState } from "react";
import { FormattedMessage } from "react-intl";

import Button from "common/core/button";

type Props = {
  onSubmit: (formValues?: undefined) => Promise<unknown> | { finally: undefined };
  workToCommit?: boolean;
  disabled?: boolean;
};

function Footer({ onSubmit, disabled, workToCommit }: Props) {
  const [loading, setLoading] = useState(false);
  return (
    <footer className="NotaryProfileWizardOnboardingFooter">
      <Button
        buttonSize="large"
        buttonColor="action"
        variant="primary"
        automationId="onboarding-next"
        isLoading={loading}
        disabled={disabled}
        onClick={() => {
          const potentialPromise = onSubmit();
          if (potentialPromise.finally) {
            setLoading(true);
            // Assume we only unlock on failure...
            potentialPromise.finally(() => setLoading(false));
          }
        }}
      >
        {workToCommit ? (
          <FormattedMessage id="a9e500c8-a044-49ee-a61b-694e6c87f123" defaultMessage="Next" />
        ) : (
          <FormattedMessage
            id="c75cc01e-21e2-4ca4-a079-21601d4f2593"
            defaultMessage="Let's get started"
          />
        )}
      </Button>
    </footer>
  );
}

export default memo(Footer);
